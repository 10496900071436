<script setup lang="ts">
const { height } = useWindowSize()

function setAppHeight() {
  document.documentElement.style.setProperty(
    `--app-height`,
    `${height.value}px`,
  )
}

watch(height, setAppHeight)

onMounted(() => {
  // Set app height to CSS, mainly to resolve vh problems
  setAppHeight()
})
</script>

<template>
  <main class="app logged-out">
    <slot />
  </main>
</template>
